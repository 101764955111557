import { Button, ButtonProps } from "antd";
import styled from "styled-components";
import { styledComponentTheme } from "../../styles/styled-component";

const StyledButton = styled(Button)`
  transition: all 0.25s ease-in-out;
  &.ant-btn-primary {
    &:hover {
      box-shadow: 0 4px 8px rgba(26, 179, 116, 0.35);
      background-color: ${styledComponentTheme.primaryColor};
    }
  }
`;

const VuiButton = (props: ButtonProps) => {
  const { type = "primary", ...otherProps } = props;

  return <StyledButton type={type} {...otherProps} />;
};

export default VuiButton;
