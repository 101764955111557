import React from "react";

const MenuIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 1.5H22M2 16.5H10.8889H2ZM2 9H16.4444H2Z"
      stroke="#222222"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MenuIcon;
