import React from "react";

const MailIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.49992 0.666748C1.30927 0.666748 0.333252 1.64277 0.333252 2.83341V9.16675C0.333252 10.3574 1.30927 11.3334 2.49992 11.3334H11.4999C12.6906 11.3334 13.6666 10.3574 13.6666 9.16675V2.83341C13.6666 1.64277 12.6906 0.666748 11.4999 0.666748H2.49992ZM2.49992 1.66675H11.4999C12.1499 1.66675 12.6666 2.18339 12.6666 2.83341V3.2019L6.99992 6.26506L1.33325 3.2019V2.83341C1.33325 2.18339 1.8499 1.66675 2.49992 1.66675ZM1.33325 4.33862L6.76229 7.27352C6.83529 7.31295 6.91695 7.33359 6.99992 7.33359C7.08289 7.33359 7.16455 7.31295 7.23755 7.27352L12.6666 4.33862V9.16675C12.6666 9.81677 12.1499 10.3334 11.4999 10.3334H2.49992C1.8499 10.3334 1.33325 9.81677 1.33325 9.16675V4.33862Z"
      fill="#383838"
    />
  </svg>
);

export default MailIcon;
