import React from "react";

const PhoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.347 0.337948C4.12758 0.321985 3.90276 0.344412 3.68229 0.409562L2.89323 0.643286C2.05241 0.891955 1.39545 1.55908 1.17318 2.4011C0.679651 4.27368 1.24422 6.49015 2.71354 8.98508C4.18079 11.4766 5.85267 13.06 7.74088 13.5769C8.59121 13.8096 9.50825 13.5873 10.1523 12.989L10.7526 12.4323C11.4243 11.8087 11.5244 10.77 10.9818 10.032L10.1146 8.85422C9.64974 8.22284 8.83032 7.95403 8.07682 8.1804L6.72005 8.58665C6.6631 8.60328 6.52681 8.52571 6.4082 8.42323L6.40755 8.42258C6.1963 8.2389 5.90362 7.86763 5.59049 7.33665V7.33599C5.25474 6.76576 5.11153 6.39282 5.05404 6.17258C4.99651 5.95225 5.01047 5.92545 5.01888 5.85097C5.02325 5.81381 5.04091 5.77937 5.07096 5.75201L5.07161 5.75136L6.08333 4.82623C6.66296 4.29635 6.83795 3.44956 6.51107 2.73704L5.90104 1.40631V1.40566C5.61384 0.779623 5.00509 0.385822 4.347 0.337948ZM4.27474 1.33534C4.5808 1.35821 4.86264 1.5418 4.99154 1.82297L5.60221 3.1537C5.75 3.47584 5.67589 3.84448 5.40885 4.0886L4.39713 5.01308C4.18973 5.20219 4.0571 5.46002 4.02539 5.73834V5.73899C4.01713 5.81218 3.99761 6.08436 4.08659 6.42519C4.17557 6.76602 4.35927 7.21683 4.72851 7.84381H4.72917C5.07369 8.42814 5.39931 8.87207 5.75195 9.17844H5.7526C5.86712 9.27759 6.34709 9.73759 7 9.54693L7.00195 9.54628L8.36458 9.13808C8.71842 9.03178 9.09676 9.15871 9.30924 9.44732L10.1764 10.6251C10.422 10.9596 10.3791 11.4144 10.0723 11.6993L9.472 12.2566C9.08812 12.6133 8.51805 12.7526 8.00456 12.612C6.4781 12.1942 4.9676 10.8418 3.57552 8.47792C2.1815 6.11085 1.7455 4.15306 2.13997 2.65631C2.27037 2.16232 2.6699 1.75227 3.17708 1.60227L3.96549 1.36855C4.0682 1.3382 4.17272 1.32772 4.27474 1.33534Z"
      fill="#383838"
    />
  </svg>
);

export default PhoneIcon;
