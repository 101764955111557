import styled from "styled-components";
import { convertToRem } from "../../../../styles/global";
import { Link } from "react-router-dom";
import {
  StyledDropdownLinkWrapperProps,
  StyledMenuLinkProps,
} from "./interface";
import {
  breakpoints,
  styledComponentTheme,
} from "../../../../styles/styled-component";
import { Menu } from "antd";

export const StyledPageHeader = styled.div`
  transition: all 0.25s ease-out;
  background-color: white;
  @media (min-width: ${breakpoints.md}) {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;

    &.scrolled {
      top: 0;
      box-shadow: 0px -10px 20px #777777;
      transition: all 0.5s ease-in-out;

      .header-layout {
        height: 74px;
      }
    }
  }
`;

export const StyledLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  transition: all 0.25s ease-out;

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const StyledMobileMenu = styled(Menu)`
  border-inline-end: none !important;

  .ant-menu-item {
    padding: 0 !important;
    font-size: ${convertToRem(14)};
    font-weight: 600;
  }

  .ant-menu-submenu .ant-menu-submenu-title {
    &:hover {
      background-color: white;
    }
    padding: 0 !important;
    font-size: ${convertToRem(14)};
    font-weight: 600;
  }

  .ant-menu-item-selected {
    background-color: white;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: white !important;
    .ant-menu-item {
      padding-left: ${convertToRem(20)} !important;
      background-color: white !important;
    }
  }

  .ant-menu-submenu-arrow {
    width: 0;
  }

  @media (max-width: ${breakpoints.md}) {
    .ant-menu-item,
    .ant-menu-submenu .ant-menu-submenu-title {
      font-size: ${convertToRem(16)};
    }
  }
`;

export const StyledMobileLayout = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const StyledMobileHeader = styled.div`
  padding: ${convertToRem(16)} 0;
  height: ${convertToRem(80)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLogo = styled.div`
  width: 210px;

  img {
    width: 100%;
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  grid-gap: ${convertToRem(40)};
`;

export const StyledDropdownLinkWrapper = styled.div<StyledDropdownLinkWrapperProps>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius}px;
  box-shadow: ${(props) => props.boxShadow};
  padding: ${convertToRem(16)} ${convertToRem(8)};
  margin-top: ${convertToRem(20)};
  min-width: ${convertToRem(200)};
`;

export const StyledMenuLink = styled(Link)<StyledMenuLinkProps>`
  text-decoration: none;
  color: ${(props) =>
    props.status === "active"
      ? props.theme.primaryColor
      : props.theme.textColor};
  padding: ${(props) =>
    props.isDropdownLink ? `${convertToRem(5)} ${convertToRem(12)}` : 0};
  font-size: ${convertToRem(14)};
  font-weight: 600;

  &:hover {
    color: ${styledComponentTheme.primaryColor};
  }
`;

export const StyledSubMenuText = styled.div`
  cursor: default;
  font-size: ${convertToRem(14)};
  font-weight: 600;
`;
