import { ValueType } from "../components/select/interface";
import { Select } from "../models/select";

export const ENV = {
  getTinyApiKey: () => window._env_.REACT_APP_TINY_API_KEY,
  getApiEndPoint: () => window._env_.REACT_APP_PASSPORT_END_POINT,
  getPassportClientId: () => window._env_.REACT_APP_PASSPORT_CLIENT_ID,
  getPassportClientSecret: () => window._env_.REACT_APP_PASSPORT_CLIENT_SECRET,
  getHostAppUrl: () => window._env_.REACT_APP_HOST_APP_URL,
  getAppName: () => window._env_.REACT_APP_NAME,
  getWhatsappNumber: () => window._env_.REACT_APP_WHATSAPP_NUMBER,
};

export const ALL_SELECT_OPTION: Select = {
  id: 0,
  name: "All",
};

export const SORT_OPTIONS: ValueType[] = [
  {
    label: "Newest",
    value: "desc",
  },
  {
    label: "Oldest",
    value: "asc",
  },
];
