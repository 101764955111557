import { Spin } from "antd";
import React from "react";
import { VuiLoadingScreenProps } from "./interface";

const VuiLoadingScreen = ({ height = "400px" }: VuiLoadingScreenProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: height,
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default VuiLoadingScreen;
