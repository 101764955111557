import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./i18n";
import "antd/dist/reset.css";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { styledComponentTheme } from "./styles/styled-component";
import { antDesignTheme } from "./styles/ant-design";
import { convertToRem } from "./styles/global";
import { pdfjs } from "react-pdf";
import ScrollToTop from "./components/scroll-to-top";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const GlobalStyle = createGlobalStyle`
  html, body {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: ${styledComponentTheme.fontFamily};
    font-size: ${convertToRem(styledComponentTheme.fontSize)};
    line-height: 2;
    overflow-x: hidden;
  }

  .ant-btn {
    font-weight: 600;
  }
  
  a:hover {
    color: ${styledComponentTheme.primaryColor};
  }
`;

root.render(
  <HelmetProvider>
    <ThemeProvider theme={styledComponentTheme}>
      <ConfigProvider theme={antDesignTheme} locale={enUS}>
        <GlobalStyle />
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </ThemeProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
