import { TFunction, TOptions } from "i18next";
import { AxiosError } from "axios";
import { notification } from "antd";
import { IconType } from "antd/lib/notification/interface";
import _ from "lodash";

export const translation = (
  tFunction: TFunction,
  key: string,
  options?: TOptions
) => {
  return options ? tFunction(key, options) : tFunction(key);
};

export const openNotification = (
  type: IconType,
  message: string,
  description?: string
) => {
  notification[type]({
    message: message,
    description: description,
    placement: "top",
  });
};

export const handleBackendError = (e: AxiosError, msg: string = "") => {
  const err = e.response?.data as any;

  if (err && e.response?.status !== 404) {
    Object.keys(err).forEach((key) => {
      let text = msg;
      if (!err[key] || err[key] === "Server Error") {
        text = msg;
      } else {
        text = err[key];
      }

      if (_.isObject(text)) {
        Object.keys(text).forEach((childKey) => {
          openNotification("error", _.get(text, `${childKey}`)[0]);
        });
      } else {
        openNotification("error", text);
      }
    });
  } else {
    openNotification("error", msg);
  }
};

// export const getPDFThumbnail = (url: string) => {
//   let result = "";
//   console.log(url);
//
//   pdfjsLib.getDocument(url).promise.then((pdf) => {
//     // Load the first page
//     pdf.getPage(1).then((page) => {
//       // Render the page as a thumbnail
//       const viewport = page.getViewport({ scale: 0.5 });
//       const canvas = document.createElement("canvas");
//       const context = canvas.getContext("2d");
//       canvas.width = viewport.width;
//       canvas.height = viewport.height;
//       page
//         .render({
//           canvasContext: context as object,
//           viewport: viewport,
//         })
//         .promise.then(() => {
//           // Convert the canvas to a data URL
//           result = canvas.toDataURL();
//         });
//     });
//   });
//
//   return result;
// };
