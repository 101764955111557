import { styledComponentTheme } from "./styled-component";
import { ThemeConfig } from "antd/es/config-provider/context";

export const antDesignTheme: ThemeConfig = {
  token: {
    colorPrimary: styledComponentTheme.primaryColor,
    fontFamily: styledComponentTheme.fontFamily,
    fontSize: styledComponentTheme.fontSize,
  },
  components: {
    Button: {
      controlHeight: 38,
      fontSize: 13,
    },
    Form: {
      fontSize: 14,
      fontSizeLG: 14,
    },
    Input: {
      fontSize: 14,
      fontSizeLG: 14,
    },
    Select: {
      fontSize: 14,
      fontSizeLG: 14,
    },
  },
};
