import React from "react";
import { RouteObject } from "react-router-dom";
import PageLayout from "../layout/page";
import VuiLoadingScreen from "../components/loading-screen";

const Home = React.lazy(() => import("../pages/home"));
const About = React.lazy(() => import("../pages/about"));
const Promotion = React.lazy(() => import("../pages/promotion"));
const PromotionDetail = React.lazy(() => import("../pages/promotion/detail"));
const Contact = React.lazy(() => import("../pages/contact"));
const Product = React.lazy(() => import("../pages/product"));
const ProductBrand = React.lazy(() => import("../pages/product/brand"));
const ProductBrandDetail = React.lazy(
  () => import("../pages/product/brand/detail")
);
const News = React.lazy(() => import("../pages/news"));
const NewsDetail = React.lazy(() => import("../pages/news/detail"));
const ProductArticle = React.lazy(() => import("../pages/product-article"));
const ProductArticleDetail = React.lazy(
  () => import("../pages/product-article/detail")
);
const ProductHighlightDetail = React.lazy(
  () => import("../pages/product-highlight/detail")
);

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <PageLayout />,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
            <Home />
          </React.Suspense>
        ),
      },
      {
        path: "about",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
            <About />
          </React.Suspense>
        ),
      },
      {
        path: "product",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
                <Product />
              </React.Suspense>
            ),
          },
          {
            path: "brand",
            children: [
              {
                path: ":id",
                children: [
                  {
                    path: "",
                    element: (
                      <React.Suspense
                        fallback={<VuiLoadingScreen height="100vh" />}
                      >
                        <ProductBrand />
                      </React.Suspense>
                    ),
                  },
                  {
                    path: ":productId",
                    element: (
                      <React.Suspense
                        fallback={<VuiLoadingScreen height="100vh" />}
                      >
                        <ProductBrandDetail />
                      </React.Suspense>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "contact",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
            <Contact />
          </React.Suspense>
        ),
      },
      {
        path: "promotion",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
                <Promotion />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
                <PromotionDetail />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "news",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
                <News />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
                <NewsDetail />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "product-article",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
                <ProductArticle />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
                <ProductArticleDetail />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "product-highlight/:id",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen height="100vh" />}>
            <ProductHighlightDetail />
          </React.Suspense>
        ),
      },
    ],
  },
];
