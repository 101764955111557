import { useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { IResource } from "../utils/interfaces/resource.interface";
import { Setting } from "../models/setting";
import SettingRepository from "../repositories/setting-repository";

export const useGetSetting = () => {
  const [settingData, setSettingData] = useState<Setting>();

  const loadSettingData = () => {
    return new Promise((resolve, reject) => {
      SettingRepository.get()
        .then((response: AxiosResponse<IResource<Setting>>) => {
          setSettingData(response.data.data);
          resolve(true);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  return {
    settingData,
    loadSettingData,
  };
};
