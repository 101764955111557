import { TFunction } from "i18next";

export type MenuList = {
  name: string;
  link: string;
  key: string;
  type: string;
  children?: MenuList[];
};

export const generateMenu = (t: TFunction): MenuList[] => [
  { name: t("common.text.home"), link: "/", key: "home", type: "text" },
  { name: t("common.text.about"), link: "/about", key: "about", type: "text" },
  {
    name: t("common.text.product"),
    link: "/product",
    key: "product",
    type: "text",
  },
  {
    name: t("common.text.information"),
    link: "/information",
    key: "information",
    type: "dropdown",
    children: [
      {
        name: t("common.text.promotion"),
        key: "promotion",
        link: "/promotion",
        type: "text",
      },
      {
        name: t("common.text.news"),
        key: "news",
        link: "/news",
        type: "text",
      },
      {
        name: t("common.text.productArticle"),
        key: "product-article",
        link: "/product-article",
        type: "text",
      },
    ],
  },
  {
    name: t("common.text.contactUs"),
    link: "/contact",
    key: "contact",
    type: "button",
  },
];
