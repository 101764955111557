export const breakpoints = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
};

export const breakpointsWidth = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1160px",
};

export const styledComponentTheme = {
  primaryColor: "#1AB374",
  textColor: "#383838",
  textLightColor: "#878787",
  fontFamily: `'Plus Jakarta Sans', sans-serif`,
  fontSize: 16,
  secondaryColor: "#002B52",
  secondaryBgColor: "#F5F8FA",
};
