import styled from "styled-components";
import {
  breakpoints,
  styledComponentTheme,
} from "../../../../styles/styled-component";
import { convertToRem } from "../../../../styles/global";

export const StyledTitle = styled.div`
  font-weight: 700;
  color: ${styledComponentTheme.secondaryColor};
  margin-bottom: ${convertToRem(16)};
  font-size: ${convertToRem(14)};
`;

export const StyledLeftFooter = styled.div`
  padding-right: ${convertToRem(50)};
  font-size: ${convertToRem(14)};

  @media (max-width: ${breakpoints.md}) {
    padding-right: 0;
  }
`;

export const StyledFooter = styled.div`
  color: ${styledComponentTheme.textColor};
  padding: ${convertToRem(56)} 0;

  a {
    font-size: ${convertToRem(14)};
    color: ${styledComponentTheme.textColor};
    &:hover {
      color: ${styledComponentTheme.primaryColor};
    }
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 28px;
  height: 100%;
  font-size: ${convertToRem(14)};

  @media (max-width: ${breakpoints.md}) {
    height: unset;
  }
`;

export const StyledSubscribeText = styled.div`
  color: ${styledComponentTheme.primaryColor};
  font-weight: 700;
  font-size: ${convertToRem(14)};
  cursor: pointer;
`;

export const StyledCopyright = styled.div`
  font-size: ${convertToRem(14)};
  margin-top: ${convertToRem(12)};
  color: ${styledComponentTheme.secondaryColor};
`;
