import { api, createCancelTokenHandler } from "../utils/services";
import { ENV } from "../constants";
import { SubscribePayload } from "../layout/page/components/footer";

const SubscribeRepository = {
  create: function (payload: SubscribePayload, params: any = null) {
    return api.post(`${ENV.getApiEndPoint()}/api/subscribes`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(SubscribeRepository);

export default SubscribeRepository;
