import { api, createCancelTokenHandler } from "../utils/services";
import { ENV } from "../constants";

const SettingRepository = {
  get: function (params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/settings`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.get.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(SettingRepository);

export default SettingRepository;
