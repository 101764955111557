import React from "react";

const LinkedinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.15909 2C3.58646 2 3.03729 2.22747 2.63238 2.63238C2.22747 3.03729 2 3.58646 2 4.15909C2 4.73172 2.22747 5.28089 2.63238 5.6858C3.03729 6.09071 3.58646 6.31818 4.15909 6.31818C4.73172 6.31818 5.28089 6.09071 5.6858 5.6858C6.09071 5.28089 6.31818 4.73172 6.31818 4.15909C6.31818 3.58646 6.09071 3.03729 5.6858 2.63238C5.28089 2.22747 4.73172 2 4.15909 2ZM2.86364 8.04545C2.38605 8.04545 2 8.4315 2 8.90909V20.1364C2 20.614 2.38605 21 2.86364 21H5.45455C5.93214 21 6.31818 20.614 6.31818 20.1364V8.90909C6.31818 8.4315 5.93214 8.04545 5.45455 8.04545H2.86364ZM8.90909 8.04545C8.4315 8.04545 8.04545 8.4315 8.04545 8.90909V20.1364C8.04545 20.614 8.4315 21 8.90909 21H11.5C11.9776 21 12.3636 20.614 12.3636 20.1364V13.6591C12.3636 12.4681 13.3318 11.5 14.5227 11.5C15.7137 11.5 16.6818 12.4681 16.6818 13.6591V20.1364C16.6818 20.614 17.0679 21 17.5455 21H20.1364C20.614 21 21 20.614 21 20.1364V13.2273C21 10.3695 18.676 8.04545 15.8182 8.04545C14.4899 8.04545 13.2817 8.55156 12.3636 9.37633V8.90909C12.3636 8.4315 11.9776 8.04545 11.5 8.04545H8.90909Z"
      fill="#002B52"
    />
  </svg>
);

export default LinkedinIcon;
