import React from "react";

const YoutubeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9254 3.5C8.60472 3.5 5.5194 3.74046 3.75887 4.11426C3.75512 4.11527 3.75138 4.1163 3.74764 4.11737C2.42127 4.41943 1.17233 5.385 0.909401 6.89709C0.909052 6.89917 0.908712 6.90124 0.90838 6.90332C0.706391 8.12202 0.5 9.71068 0.5 12C0.5 14.285 0.707099 15.8315 0.96249 17.1143C1.23186 18.5818 2.45197 19.5766 3.79869 19.8826C3.80513 19.8844 3.8116 19.8862 3.81809 19.8878C5.6555 20.2558 8.65373 20.5 11.9745 20.5C15.2952 20.5 18.295 20.2558 20.1329 19.8878C20.1393 19.8862 20.1458 19.8844 20.1523 19.8826C21.4786 19.5806 22.7276 18.615 22.9905 17.1029C22.9912 17.0991 22.9919 17.0953 22.9925 17.0915C23.1914 15.8479 23.4491 14.2608 23.5 11.9678C23.5 11.962 23.5 11.9561 23.5 11.9502C23.5 9.65596 23.2416 8.05742 22.9895 6.79126C22.7222 5.32063 21.5007 4.32316 20.1523 4.01672C20.1317 4.01207 20.1109 4.00826 20.09 4.00531C18.2969 3.74913 15.2467 3.5 11.9254 3.5ZM9.99075 8.01666C10.1844 8.0126 10.3845 8.05914 10.5727 8.16711L15.07 10.7497C15.8284 11.1853 15.8284 12.2845 15.07 12.7201L10.5727 15.3027C9.82049 15.734 8.88302 15.1877 8.88302 14.317V9.15179C8.88302 8.49876 9.40988 8.02886 9.99075 8.01666Z"
      fill="#002B52"
    />
  </svg>
);

export default YoutubeIcon;
