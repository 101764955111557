import { Outlet } from "react-router-dom";
import PageHeader from "./components/header";
import PageFooter from "./components/footer";
import { useGetSetting } from "../../hooks/use-get-setting";
import { useEffect } from "react";
import { Setting } from "../../models/setting";
import styled from "styled-components";
import { convertToRem } from "../../styles/global";
import { breakpoints } from "../../styles/styled-component";
import FloatingWhatsappIcon from "../../assets/icons/floating-whatsapp";
import { ENV } from "../../constants";

export type RouterContextType = { settingData: Setting | undefined };

const StyleOutletWrapper = styled.div`
  overflow: hidden;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${convertToRem(80)};
  }
`;

const StyledFloatingIcon = styled.div`
  position: fixed;
  right: ${convertToRem(24)};
  bottom: ${convertToRem(24)};
  z-index: 10;
`;

const PageLayout = () => {
  const { settingData, loadSettingData } = useGetSetting();

  useEffect(() => {
    (async () => {
      await loadSettingData();
    })();
  }, []);

  return (
    <div>
      <PageHeader />

      {ENV.getWhatsappNumber() && (
        <StyledFloatingIcon>
          <FloatingWhatsappIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(`https://wa.me/${ENV.getWhatsappNumber()}`, "_blank");
            }}
          />
        </StyledFloatingIcon>
      )}

      <StyleOutletWrapper>
        <Outlet context={{ settingData }} />
      </StyleOutletWrapper>

      <PageFooter settingData={settingData} />
    </div>
  );
};

export default PageLayout;
