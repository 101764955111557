import React from "react";

const FloatingWhatsappIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1403_4911)">
      <path
        d="M31 62C48.1208 62 62 48.1208 62 31C62 13.8792 48.1208 0 31 0C13.8792 0 0 13.8792 0 31C0 48.1208 13.8792 62 31 62Z"
        fill="#1AB374"
      />
      <path
        d="M31 18.5C24.1063 18.5 18.5 24.1063 18.5 31C18.5 32.9937 18.9812 34.9625 19.8937 36.7312L18.5563 41.5188C18.4063 42.0625 18.5625 42.6438 18.9563 43.0438C19.2563 43.3375 19.6562 43.5 20.0625 43.5C20.2062 43.5 20.3438 43.4813 20.4813 43.4438L25.2688 42.1063C27.0375 43.0188 29.0125 43.5 31 43.5C37.8937 43.5 43.5 37.8937 43.5 31C43.5 24.1063 37.8937 18.5 31 18.5ZM37.475 35.6063C37.2 36.375 35.85 37.1188 35.2438 37.175C34.6375 37.2313 34.0688 37.45 31.2812 36.35C27.9188 35.025 25.7937 31.5813 25.6312 31.3562C25.4625 31.1375 24.2812 29.5688 24.2812 27.9375C24.2812 26.3125 25.1375 25.5125 25.4375 25.1812C25.7438 24.85 26.1 24.7687 26.3188 24.7687C26.5437 24.7687 26.7625 24.7688 26.9563 24.775C27.1875 24.7875 27.45 24.8 27.7 25.35C27.9937 26 28.6375 27.6375 28.7188 27.8062C28.8 27.9688 28.8562 28.1625 28.7437 28.3812C28.6375 28.6 28.5813 28.7375 28.4125 28.9313C28.25 29.125 28.0687 29.3625 27.9187 29.5125C27.7562 29.675 27.5813 29.8563 27.775 30.1875C27.9688 30.5188 28.6313 31.6 29.6125 32.475C30.875 33.6 31.9375 33.95 32.2688 34.1187C32.6 34.2812 32.7937 34.2563 32.9875 34.0312C33.1813 33.8125 33.8125 33.0688 34.0375 32.7375C34.2563 32.4062 34.475 32.4625 34.7812 32.575C35.0812 32.6813 36.7062 33.4812 37.0375 33.65C37.3687 33.8125 37.5875 33.8938 37.6687 34.0312C37.75 34.1687 37.75 34.8313 37.475 35.6063Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1403_4911">
        <rect width="62" height="62" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FloatingWhatsappIcon;
