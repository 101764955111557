import styled from "styled-components";
import {
  breakpoints,
  breakpointsWidth,
  styledComponentTheme,
} from "./styled-component";
import { Empty } from "antd";
import { Link } from "react-router-dom";

export const convertToRem = (value: number) => {
  return `${value / 16}rem`;
};

export const StyledShowDesktop = styled.div`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

export const StyledShowLargeDesktop = styled.div`
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

export const StyledShowMobile = styled.div`
  display: none;

  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  margin: auto;
  position: relative;

  @media (max-width: ${breakpoints.xs}) {
    padding: 0 ${convertToRem(20)};
  }

  @media (min-width: ${breakpoints.xs}) {
    max-width: ${breakpointsWidth.xs};
    padding: 0 ${convertToRem(20)};
  }

  @media (min-width: ${breakpoints.sm}) {
    max-width: ${breakpointsWidth.sm};
    padding: 0 ${convertToRem(20)};
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: ${breakpointsWidth.md};
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: ${breakpointsWidth.lg};
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: ${breakpointsWidth.xl};
  }
`;

export const StyledSection = styled.div`
  margin-top: ${convertToRem(80)};
  margin-bottom: ${convertToRem(80)};

  @media (max-width: ${breakpoints.md}) {
    margin-top: ${convertToRem(50)};
    margin-bottom: ${convertToRem(50)};
  }
`;

export const StyledCard = styled.div`
  background-color: #ffffff;
  border: ${convertToRem(2)} solid #f1faf7;
  box-shadow: 0 0 ${convertToRem(2)} rgba(26, 179, 116, 0.16);
  border-radius: ${convertToRem(16)};
`;

export const StyledPrimaryText = styled.div`
  color: ${styledComponentTheme.primaryColor};
  font-size: ${convertToRem(14)};
  font-weight: 700;

  &:hover {
    color: ${styledComponentTheme.primaryColor};
  }
`;

export const StyledFilter = styled.div`
  width: 100%;

  .ant-select {
    width: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: ${convertToRem(345)};
  }
`;

type StyledPageTitleProps = {
  fontSize?: number;
  marginBottom?: number;
};

export const StyledPageTitle = styled.div<StyledPageTitleProps>`
  font-size: ${(props) =>
    props.fontSize ? convertToRem(props.fontSize) : convertToRem(48)};
  font-weight: 700;
  color: ${styledComponentTheme.secondaryColor};
  margin-bottom: ${(props) =>
    props.marginBottom ? convertToRem(props.marginBottom) : 0};
  line-height: 1.5;
`;

export const StyledEmpty = styled(Empty)`
  height: ${convertToRem(280)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledNavigationIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${convertToRem(40)};
  font-size: ${convertToRem(14)};
  height: ${convertToRem(40)};
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 1px 3px rgba(26, 179, 116, 0.25);
  cursor: pointer;

  .anticon {
    color: ${styledComponentTheme.primaryColor};
  }
`;

export const StyledWYSIWYGContentWrapper = styled.div`
  font-size: ${convertToRem(14)};
  line-height: 2;
  img {
    width: 100%;
    height: auto;
  }
`;

export const StyledLink = styled(Link)`
  color: ${styledComponentTheme.primaryColor};
  text-decoration: none;
  font-weight: 700;
  position: relative;
  width: fit-content;
  font-size: ${convertToRem(14)};

  &:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 0;
    transition: all 0.25s ease-in-out;
    background-color: ${styledComponentTheme.primaryColor};
    bottom: -${convertToRem(6)};
    opacity: 0;
    left: 0;
  }

  &:hover {
    &:before {
      width: 100%;
      opacity: 1;
    }
  }
`;
